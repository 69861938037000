export default function () {
  const toggle = document.getElementById("toggle-password-visibility")
  if (toggle) {
    toggle.addEventListener("click", function () {
      const x = document.getElementsByName("user[password]")[0] as HTMLInputElement
      if (x.type === "password") {
        x.type = "text"
      } else {
        x.type = "password"
      }
    })
  }
}
